import { useEffect, useState } from "react";
import FetchData from "../../common/FetchData";
import { Tooltip } from "react-tooltip";
import { PRODUCT_DETAILS } from "../../common/ApiEndpoints";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import { LoadingMain } from "../../common/Loading";
import DataModal from "../../common/DataModal";
import ProductImage from "./ProductImage";
import Variant from "./Variant";

export default function ProductDetails({
  productId,
  handleSetHideProductDetails,
  unAuthorizedFetch,
}) {
  const { handleOpenMsgModal } = UseMsgModalContext();

  const url = PRODUCT_DETAILS + productId;
  const isList = false;

  const [productDetails, setProductDetails] = useState({});
  const [showDataModal, setShowDataModal] = useState(false);
  const { data, loading, error } = FetchData(
    url,
    isList,
    {},
    unAuthorizedFetch
  );

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      setProductDetails(data);
      setShowDataModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleOpenMsgModal({ title: "error", body: error.message });
    }
  }, [error, handleOpenMsgModal]);

  if (loading) {
    return <LoadingMain />;
  }

  return (
    <>
      {showDataModal && (
        <DataModal
          title={"Product Details"}
          handleCloseDataModal={handleSetHideProductDetails}
          widerModal={true}
        >
          <div className="highlighted-section">
            <div className="row p-2">
              <div className="col col-12 col-lg-6 col-xl-4 d-flex justify-content-center">
                <ProductImage
                  imageSet={productDetails.image_set ? productDetails.image_set : []}
                  imageSize={"large"}
                />
              </div>
              <div className="col col-12 col-lg-6 col-xl-8 text-start p-2">
                <h6>
                  <strong>Name: </strong>
                  {productDetails.name}
                </h6>
                <h6>
                  <strong>Category: </strong>
                  {productDetails.category_name}
                </h6>
                <h6>
                  <strong>Total Stock: </strong>
                  {productDetails.total_stock_count}
                </h6>
                <h6>
                  <strong>Total Variants: </strong>
                  {productDetails.number_of_variants}
                </h6>
                <h6>
                  <strong>Description:</strong>
                </h6>
                <p>{productDetails.description}</p>
              </div>
            </div>
          </div>

          {!unAuthorizedFetch && (
            <>
              <div className="highlighted-section overflow-auto">
                <table className="table table-striped table-bordered my-2">
                  <thead>
                    <tr>
                      <th scope="col">SKU</th>
                      <th scope="col">Name</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Weight(KG)</th>
                      <th scope="col">Min Qty</th>
                      <th scope="col">Max Qty</th>
                      <th scope="col">Stock Count</th>
                      <th scope="col">Available</th>

                      <th scope="col">In Cart</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productDetails.variants &&
                      productDetails.variants.map((variant) => (
                        <Variant key={variant.id} variant={variant} />
                      ))}
                  </tbody>
                </table>
              </div>
              <Tooltip // variant add tooltip here (in parent) for better rendering
                id="tooltip-add"
                style={{
                  backgroundColor: "#313131",
                  color: "#fff",
                  fontSize: "9px",
                  padding: "0.3rem",
                }}
              />
              <Tooltip // variant add tooltip here (in parent) for better rendering
                id="tooltip-remove"
                style={{
                  backgroundColor: "#313131",
                  color: "#fff",
                  fontSize: "9px",
                  padding: "0.3rem",
                }}
              />
            </>
          )}
        </DataModal>
      )}
    </>
  );
}
