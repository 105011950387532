import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter, faRedo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

export default function Search({ searchValue, setSearchValue, performReload }) {
  const handlePerformReload = () => {
    performReload();
    document.getElementById("product-search-input").value = "";
  };

  const handleSetSearchValue = () => {
    let searchValue = document.getElementById("product-search-input").value;
    setSearchValue(searchValue);
  };

  return (
    <div className="container d-flex justify-content-between">
      <div className="wrap-input" style={{ width: "90%" }}>
        <input
          className="input"
          type="search"
          placeholder="Search by product name or description"
          id="product-search-input"
        />
      </div>
      <button
        data-tooltip-id="tooltip-product-reload"
        data-tooltip-content="reload"
        data-tooltip-place="bottom"
        onClick={handlePerformReload}
        disabled={searchValue === null ? true : false}
      >
        <FontAwesomeIcon icon={faRedo} />
      </button>
      <Tooltip
        id="tooltip-product-reload"
        style={{
          backgroundColor: "#313131",
          color: "#fff",
          fontSize: "9px",
          padding: "0.3rem",
        }}
      />
      <button
        data-tooltip-id="tooltip-product-search"
        data-tooltip-content="search"
        data-tooltip-place="bottom"
        onClick={handleSetSearchValue}
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
      <Tooltip
        id="tooltip-product-search"
        style={{
          backgroundColor: "#313131",
          color: "#fff",
          fontSize: "9px",
          padding: "0.3rem",
        }}
      />
    </div>
  );
}
